exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-hire-expert-jsx": () => import("./../../../src/pages/hire-expert.jsx" /* webpackChunkName: "component---src-pages-hire-expert-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-schedule-call-jsx": () => import("./../../../src/pages/schedule-call.jsx" /* webpackChunkName: "component---src-pages-schedule-call-jsx" */),
  "component---src-pages-services-design-jsx": () => import("./../../../src/pages/services/design.jsx" /* webpackChunkName: "component---src-pages-services-design-jsx" */),
  "component---src-pages-services-development-jsx": () => import("./../../../src/pages/services/development.jsx" /* webpackChunkName: "component---src-pages-services-development-jsx" */),
  "component---src-templates-blog-post-index-jsx": () => import("./../../../src/templates/blog-post/index.jsx" /* webpackChunkName: "component---src-templates-blog-post-index-jsx" */)
}

